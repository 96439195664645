import React, { Component, Suspense } from "react";
import { connect } from "react-redux";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import { IntlProvider } from "react-intl";
import AppLocale from "./lang";
import { getDirection } from "./helpers/Utils";
import { ModalTimeOut } from "./components/Alert/ModalAlert";

import {
  getAuth,
  getUser,
  getCompanyprofile,
  getRole,
  countryAddress,
  provinceAddress,
  getUserEmail,
  getCompanyPkindev,
} from "./redux/actions";
import SpinnerLoading from "./components/Loading/SpinnerLoading";
import BillingNote from "./views/BillingNote";

const ViewMain = React.lazy(() =>
  import(/* webpackChunkName: "views" */ "./views")
);
const ViewAdmin = React.lazy(() => import("./views/Home"));
const ViewError = React.lazy(() => import("./views/error"));
const ViewLogin = React.lazy(() => import("./views/login"));
const ViewCreateNewPassword = React.lazy(() =>
  import("./views/createNewPassword")
);

class App extends Component {
  constructor(props) {
    super(props);
    const direction = getDirection();
    if (direction.isRtl) {
      document.body.classList.add("rtl");
      document.body.classList.remove("ltr");
    } else {
      document.body.classList.add("ltr");
      document.body.classList.remove("rtl");
    }

    this.state = {
      modal_timeout: false,
    };
  }

  componentDidMount() {
    this.props.getUserEmail();
    if (this.props.auth_user.length != 0) {
      this.props.getUser(this.props.auth_user[0].id_bill);
      this.props.getCompanyprofile({id_bill: this.props.auth_user[0].id_bill,});
      this.props.getRole(this.props.auth_user[0].id_bill);
      this.props.countryAddress();
      this.props.provinceAddress("en");
      this.props.getCompanyPkindev({id_bill: this.props.auth_user[0].id_bill,});
                                  console.log("zzzzzzz",{id_bill: this.props.auth_user[0].id_bill,});
      if (typeof localStorage.getItem("expAt") != "undefined") {
        const time = new Date().getTime();
        if (Math.floor(time / 1000) >= localStorage.getItem("expAt")) {
          localStorage.clear();
          window.location.href = "/";
        }
      }
      this.setCheckTokenEXP();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.auth_user.length != this.props.auth_user.length) {
      this.props.getUser(this.props.auth_user[0].id_bill);
      this.props.getCompanyprofile({
        id_bill: this.props.auth_user[0].id_bill,
      });
      this.props.getRole(this.props.auth_user[0].id_bill);
      this.props.countryAddress();
      this.props.provinceAddress("en");
      this.setCheckTokenEXP();
    }
  }

  setCheckTokenEXP = () => {
    let looptimeout = setInterval(() => {
      const time = new Date().getTime();
      if (Math.floor(time / 1000) >= localStorage.getItem("expAt")) {
        clearInterval(looptimeout);
        this.setState({
          modal_timeout: !this.state.modal_timeout,
        });
      }
    }, 1000);
  };

  render() {
    const { locale } = this.props;
    const currentAppLocale = AppLocale[locale];

    return (
      <>
        <div className="h-100">
          <IntlProvider
            locale={currentAppLocale.locale}
            messages={currentAppLocale.messages}
          >
            <React.Fragment>
              <Suspense fallback={<SpinnerLoading modal={true} />}>
                <Router>
                  <Switch>
                    <Route
                      path="/verify/:id"
                      render={(props) => <ViewLogin {...props} />}
                    />
                    <Route
                      path="/create_new_password/:id"
                      render={(props) => <ViewCreateNewPassword {...props} />}
                    />
                    {this.props.auth_user.length != 0 ? (
                      this.props.auth_user[0].status ? (
                        <Route
                          path="/Home"
                          render={(props) => <ViewAdmin {...props} />}
                        />
                      ) : (
                        <Redirect to="/login" />
                      )
                    ) : (
                      <Route
                        path="/login"
                        render={(props) => <ViewLogin {...props} />}
                      />
                    )}
                    <Route
                      path="/:id_company/:id_invoice"
                                           
                      render={(props) => <BillingNote {...props} />}
                    />
                    <Route
                      path="/error"
                      exact
                      render={(props) => <ViewError {...props} />}
                    />
                    <Route
                      path="/"
                      render={(props) => <ViewMain {...props} />}
                    />
                    {/* <Redirect to="/error" /> */}
                  </Switch>
                </Router>
              </Suspense>
            </React.Fragment>
          </IntlProvider>
        </div>
        <ModalTimeOut
          show={this.state.modal_timeout}
          saveData={() => {
            localStorage.clear();
            window.location.href = "/";
          }}
        />
      </>
    );
  }
}

const mapStateToProps = ({ auth, settings }) => {
  const { locale, loading } = settings;
  const { auth_user } = auth;
  return { auth_user, locale, loading };
};
const mapActionsToProps = {
  getAuth,
  getUser,
  getCompanyprofile,
  getRole,
  countryAddress,
  provinceAddress,
  getUserEmail,
  getCompanyPkindev,
};

export default connect(mapStateToProps, mapActionsToProps)(App);
