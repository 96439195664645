import React, { Component } from "react";
import { Modal, Button, Form, Row, Col, Container } from 'react-bootstrap'

export class ModalVerifyEmail extends Component {
    render() {
        return (
            <Modal
                {...this.props}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
            >
                <Modal.Header>
                    <Modal.Title id="contained-modal-title-vcenter">
                        ALERT!
                </Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ textAlign: 'center' }}>
                    <h4>
                        Congratulations!
                        You have successfully verified the email address.
                    </h4>
                    <div className='mt-4'>
                        <Button variant='light'
                            style={{ height: '30px', width: '100px', borderColor: '#CC0505' }}
                            onClick={this.props.saveData}
                        ><p style={{ marginTop: '-6px', color: '#CC0505' }} >CLOSE</p></Button>
                    </div>
                </Modal.Body>
            </Modal>
        )
    }
}

export class ModalResetPass extends Component {
    render() {
        return (
            <Modal
                {...this.props}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
            >
                <Modal.Header>
                    <Modal.Title id="contained-modal-title-vcenter">
                        ALERT!
                </Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ textAlign: 'center' }}>
                    <h4>
                        Your password has been changed successfully! thank you.
                    </h4>
                    <div className='mt-4'>
                        <Button variant='light'
                            style={{ height: '30px', width: '100px', borderColor: '#CC0505' }}
                            onClick={this.props.saveData}
                        ><p style={{ marginTop: '-6px', color: '#CC0505' }} >CLOSE</p></Button>
                    </div>
                </Modal.Body>
            </Modal>
        )
    }
}
export class ModalTimeOut extends Component {
    render() {
        return (
            <Modal
                {...this.props}
                size="sm"
                aria-labelledby="contained-modal-title-vcenter"
            >
                <Modal.Header>
                    <Modal.Title id="contained-modal-title-vcenter">
                        TIME OUT!
                </Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ textAlign: 'center' }}>
                    <h4>Please login again</h4>
                    <div className='mt-4'>
                        <Button variant='light'
                            style={{ height: '30px', width: '100px', borderColor: '#CC0505' }}
                            onClick={this.props.saveData}
                        ><p style={{ marginTop: '-6px', color: '#CC0505' }} >CLOSE</p></Button>
                    </div>
                </Modal.Body>
            </Modal>
        )
    }
}
export class ModalExpireURL extends Component {
    render() {
        return (
            <Modal
                {...this.props}
                size="sm"
                aria-labelledby="contained-modal-title-vcenter"
            >
                <Modal.Header>
                    <Modal.Title id="contained-modal-title-vcenter">
                        ALERT!
                </Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ textAlign: 'center' }}>
                    <h4>
                        Expired URL
                    </h4>
                    <div className='mt-4'>
                        <Button variant='light'
                            style={{ height: '30px', width: '100px', borderColor: '#CC0505' }}
                            onClick={this.props.saveData}
                        ><p style={{ marginTop: '-6px', color: '#CC0505' }} >CLOSE</p></Button>
                    </div>
                </Modal.Body>
            </Modal>
        )
    }
}