module.exports = {
  "menu.app": "Home",
  "menu.admin": "Menu",
  "menu.head_page_setting_a": "Setting",
  "menu.head_page_a": "Page A",
  "menu.head_page_b": "Page B",

  //general
  "general.close": "CLOSE",
  "general.cancel": "CANCEL",
  "general.save": "SAVE",
  "general.edit": "EDIT",
  "general.view": "VIEW",


  ///1.Dashboard page
    "dashboard.heading": "Dashboard",
    "dashboard.payment": "",
    "dashboard.alert": "",
    "dashboard.number_of_project": "No. of project(s)",
    "dashboard.payment_noti": "Payment Notification",
    "dashboard.logs": "Logs",
    "dashboard.receipt_notification": "Receipt Notification",
    //1.1 payment table
    "dashboard.no": "No.",
    "dashboard.due_date": "Due Date",
    "dashboard.price": "Price",
    "dashboard.status": "Status",
    //1.2 receipt table
    "dashboard.receipt_no": "Receipt No.",
    "dashboard.date": "Date",

    
  //2.Invoice Management Page
    "invoice.invoice_management": "Invoice Management",
    "invoice.details": "Invoice Details",
    "invoice.payment_plan": "Payment Plan",
    "invoice.contract_period": "Contract Period",
    "invoice.s_date": "Start Date",
    "invoice.e_date": "End Date",
    //invoice tables
    "invoice_table_no": "No.",
    "invoice_table_invoice_no": "Invoice No.",
    "invoice_table_start_date": "Start Date",
    "invoice_table_end_date": "End Date",
    "invoice_table_due_date": "Due Date",
    "invoice_table_price": "Price(THB)",
    "invoice_table_status": "Status",
    "invoice_table_receipt": "Receipt",
    "invoice_table_action": "Action",
    "invoice_table_paid": "Paid",
    "invoice_table_pending": "Pending",
    "invoice_table_checking": "Checking",

    //3.Contract Management Page
    "contract.contract_management": "Contract Management",
    "contract.details": "Contract Details",

    //col1
    "contract.project_id": "Project ID",
    "contract.address": "Address",
    "contract.country": "Country",
    "contract.district": "District",
    "contract.province": "Province",
    "contract.Email": "Email",
    "contract.payment_plan": "Payment Plan",
    "contract.s_date": "Start Date",
    "contract.privacy_agreement": "Privacy Agreement",
    "contract.refundable_policy": "Refundable Policy",
    "contract.product_description": "Product Description",
    //col2
    "contract.company_name": "Company Name",
    "contract.sub_district": "Sub-district",
    "contract.postal_code": "Postal Code",
    "contract.phone_number": "Phone Number",
    "contract.contract_period": "Contract Period",
    "contract.price": "Price",
    "contract.e_date": "End Date",
    "contract.Terms_&_Conditions": "Terms & Conditions",
    "contract.contract_document": "Contract Document",
    "contract.customer_support": "Customer Support",
    //general in contract page
    "contract.view": "View",
    "contract.description": "Description",
    "contract.close": "CLOSE",
    "contract.req_enable": "REQUEST TO ENABLE",
    "contract.req_suspend": "REQUEST TO SUSPEND",

  //4.Menu-admin
    "menu_admin.dashboard": "Dashboard",
    "menu_admin.invoice": "Invoice",
    "menu_admin.contract": "Contract",
    "menu_admin.setting": "Setting",
    //setting-sub-menut
    "menu_admin.company_profile": "Company Profile",
    "menu_admin.project_setting": "Project Setting",
    "menu_admin.payment_setting": "Payment Setting",
    "menu_admin.user_setting": "User Setting",
    
  //5.Setting Page
    //5.1 company profile
    "setting.company_profile_heading": "Company Profile",
      //5.1.1 Modal_edit - company
      "setting.company_profile": "Company Profile",
      "setting.company_name": "Company name",
      "setting.branch_no": "Branch No.",
      "setting.head_office": "Head Office",
      "setting.address": "Address",
      "setting.address_2": "Address 2",
      "setting.country": "Country",
      "setting.province": "Province",
      "setting.district": "District",
      "setting.sub_district": "Sub District",
      "setting.postal_code": "Postal Code",
      "setting.tax_id": "TAX ID",
      "setting.currency_setup": "Currency Setup",
    
    //5.2 Project Setting
    "setting.project_setting": "Project Setting",
    "setting.project_setting_add": "Add New",
      //5.2.1 ps modal
      "setting.add_project": "Add Project",
      "setting.select_project": "Project Selection",
    //5.3 Payment Setting
    "setting.payment_setting": "Payment Setting",
    //5.4 User Setting
    "setting.user": "User Setting",
    "setting.role": "Role",
    "setting.role_details": "Role detail(s)",
    "setting.user_details": "User Details",
    "setting.add_user": "Add New",
      //5.4.1 Role Modal
      "add_role.add_role": "Add New",
      "add_role.role_name": "Role Name",
      "add_role.feature": "Feature(s)",
      "add_role.view": "View",
      "add_role.add_edit_pay": "Add/Edit/Pay",
      "add_role.delete": "Delete",
      "add_role.dashboard": "Dashboard",
      "add_role.invoice": "Invoice",
      "add_role.contract": "Contract",
      "add_role.setting": "Setting",

      //5.4.2 Add User Modal
      "add_user.add_user": "Add User",
      "add_user.first_name": "First Name",
      "add_user.last_name": "Last Name",
      "add_user.nick_name": "Nick Name",
      "add_user.gender": "Gender",
      "add_user.female": "Female",
      "add_user.male": "Male",
      "add_user.date_of_birth": "Date of Birth",
      "add_user.nationality": "Nationality",
      "add_user.email": "Email",
      "add_user.id_passport_no": "ID/Passport No.",
      "add_user.address": "Address",
      "add_user.address_2": "Address 2",
      "add_user.country": "Country",
      "add_user.province": "Province",
      "add_user.district": "District",
      "add_user.sub_district": "Sub District",
      "add_user.postal_code": "Postal Code",
      "add_user.mobile_no": "Mobile No.",
  //6.Topnav admin
  "topnav.personal_info": "Personal Information",
  "topnav.change_pw": "Change Password",
  "topnav.logout": "Log out",
    //personal info
    "topnav.personal_info": "Personal Information",
    "topnav.basic_information": "Basic Information",
    "topnav.personal_firstName": "First Name",
    "topnav.personal_lastName": "Last Name",
    "topnav.personal_nickName": "Nick Name",
    "topnav.personal_gender": "Gender",
    "topnav.personal_male": "Male",
    "topnav.personal_female": "Female",
    "topnav.personal_dob": "Date of Birth",
    "topnav.personal_nationality": "Nationality",
    "topnav.personal_email": "Email",
    "topnav.personal_id_passport_no": "ID/PASSPORT NO.",
    "topnav.personal_address": "Address",
    "topnav.personal_address_2": "Address 2",
    "topnav.personal_country": "Country",
    "topnav.personal_province": "Province",
    "topnav.personal_district": "District",
    "topnav.personal_sub_district": "Sub District",
    "topnav.personal_postal_code": "Postal Code",
    "topnav.personal_mobile_no": "Mobile No.",
    //change password
    "topnav.change_password": "Change Password",
    "topnav.current_password": "Current Password",
    "topnav.forgot_password": "Forgot Password",
    "topnav.new_password": "New Password",
    "topnav.verify_password": "Verify Password"
  






    
};


