import { defaultLocale, localeOptions } from "../../constants/defaultValues";

import {
  // ======Province=======
  PROVINCE,
  PROVINCE_SUCCESS,
  DISTRICT_SUCCESS,
  DISTRICT,
  SUBDISTRICT,
  SUBDISTRICT_SUCCESS,
  CREDITBANK_GET,
  CREDITBANK_GET_SUCCESS,

  // ======Company=======
  CHANGE_LOCALE,
  COMPANY_PROFILE_ADD,
  COMPANY_PROFILE_ADD_SUCCESS,
  COMPANY_PROFILE_GET,
  COMPANY_PROFILE_GET_SUCCESS,
  COMPANY_PROFILE_UPDATE,
  COMPANY_PROFILE_UPDATE_SUCCESS,
  COMPANY_PROFILE_DELETE,
  COMPANY_PROFILE_DELETE_SUCCESS,
  COMPANY_PROFILE_COUNTRY,
  COMPANY_PROFILE_COUNTRY_SUCCESS,
  COMPANY_PROFILE_PROVINCE,
  COMPANY_PROFILE_PROVINCE_SUCCESS,
  COMPANY_PROFILE_DISTRICT,
  COMPANY_PROFILE_DISTRICT_SUCCESS,
  COMPANY_PROFILE_SUBDISTRICT,
  COMPANY_PROFILE_SUBDISTRICT_SUCCESS,
  // COMPANY_PROFILE_ZIPCODE,
  // COMPANY_PROFILE_ZIPCODE_SUCCESS
  COMPANY_PKINDEV,
  COMPANY_PKINDEV_SUCCESS,

  // =====Project=======
  PROJECT_ADD,
  PROJECT_SET,
  PROJECT_SET_SUCCESS,
  PROJECT_ADD_SUCCESS,
  PROJECT_GET,
  PROJECT_GET_INVOICE,
  PROJECT_GET_SUCCESS,
  PROJECT_UPDATE,
  PROJECT_UPDATE_SUCCESS,
  PROJECT_DELETE,
  PROJECT_DELETE_SUCCESS,
  PROJECT_COUNTRY,
  PROJECT_COUNTRY_SUCCESS,
  PROJECT_PROVINCE,
  PROJECT_PROVINCE_SUCCESS,
  PROJECT_DISTRICT,
  PROJECT_DISTRICT_SUCCESS,
  PROJECT_SUBDISTRICT,
  PROJECT_SUBDISTRICT_SUCCESS,

  // =====User=======
  USER_GET_EMAIL,
  USER_GET_EMAIL_SUCCESS,
  USER_GET,
  USER_GET_SUCCESS,
  ROLE_GET,
  USER_ADD,
  USER_UPDATE,
  USER_PASS_UPDATE,
  USER_DELETE,
  ROLE_GET_SUCCESS,
  ROLE_ADD,
  ROLE_ADD_SUCCESS,
  ROLE_UPDATE,
  ROLE_UPDATE_SUCCESS,
  ROLE_DELETE,
  SEND_VERIFY,
  SEND_REST_PASSWORD,
  CONFIRM_VERIFY,
  CONFIRM_VERIFY_STATUS,
  SEND_REQUEST,

  // =====Payment=======
  PAYMENT_GET,
  PAYMENT_GET_SUCCESS,
  PAYMENT_ADD,
  PAYMENT_DELETE,
} from "../actions";

const INIT_STATE = {
  locale:
    localStorage.getItem("currentLanguage") &&
    localeOptions.filter(
      (x) => x.id === localStorage.getItem("currentLanguage")
    ).length > 0
      ? localStorage.getItem("currentLanguage")
      : defaultLocale,
  loading: false,
  loadingInvoice: false,
  // =======Company=======
  companyProfile: [],
  companyPkindev: [],
  selectCountry: [],
  selectProvince: [],
  selectDistrict: [],
  selectSubDistrict: [],
  selectProvince1: [],
  selectDistrict1: [],
  selectSubDistrict1: [],
  // selectZipCode: [],

  selectCreditBank: [],

  // =========Project=========
  projectSetting: [],
  projectSettingSet: [],
  
  // =========User=========
  userSetting: [],
  roleSetting: [],
  userEmail: [],

  comfirmVerify: [],

  // =========User=========
  paymentSetting: [],
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case CHANGE_LOCALE:
      return { ...state, locale: action.payload };

    // =================Company==================

    case CREDITBANK_GET:
      return { ...state, loading: true };

    case CREDITBANK_GET_SUCCESS:
      return { ...state, loading: false, selectCreditBank: action.payload };

    case PROVINCE:
      return { ...state, loading: true };

    case PROVINCE_SUCCESS:
      return { ...state, loading: false, selectProvince1: action.payload };

    case DISTRICT:
      return { ...state, loading: true };

    case DISTRICT_SUCCESS:
      return { ...state, loading: false, selectDistrict1: action.payload };

    case SUBDISTRICT:
      return { ...state, loading: true };

    case SUBDISTRICT_SUCCESS:
      return { ...state, loading: false, selectSubDistrict1: action.payload };

    case COMPANY_PKINDEV:
      return { ...state, loading: true};

    case COMPANY_PKINDEV_SUCCESS:
      return { ...state, loading: false, companyPkindev: action.payload };
      
      

    case COMPANY_PROFILE_ADD:
      return { ...state, loading: false };

    case COMPANY_PROFILE_ADD_SUCCESS:
      return { ...state, loading: false, companyProfile: action.payload };

    case COMPANY_PROFILE_GET:
      return { ...state, loading: true };

    case COMPANY_PROFILE_GET_SUCCESS:
      return { ...state, loading: false, companyProfile: action.payload };

    case COMPANY_PROFILE_UPDATE:
      return { ...state, loading: true };

    case COMPANY_PROFILE_UPDATE_SUCCESS:
      return { ...state, loading: false, companyProfile: action.payload };

    case COMPANY_PROFILE_DELETE:
      return { ...state, loading: true };

    case COMPANY_PROFILE_DELETE_SUCCESS:
      return { ...state, loading: false, companyProfile: action.payload };

    case COMPANY_PROFILE_COUNTRY:
      return { ...state, loading: true };

    case COMPANY_PROFILE_COUNTRY_SUCCESS:
      return { ...state, loading: false, selectCountry: action.payload };

    case COMPANY_PROFILE_PROVINCE:
      return { ...state, loading: true };

    case COMPANY_PROFILE_PROVINCE_SUCCESS:
      return { ...state, loading: false, selectProvince: action.payload };

    case COMPANY_PROFILE_DISTRICT:
      return { ...state, loading: true };

    case COMPANY_PROFILE_DISTRICT_SUCCESS:
      return { ...state, loading: false, selectDistrict: action.payload };

    case COMPANY_PROFILE_SUBDISTRICT:
      return { ...state, loading: true };

    case COMPANY_PROFILE_SUBDISTRICT_SUCCESS:
      return { ...state, loading: false, selectSubDistrict: action.payload };

    // case COMPANY_PROFILE_ZIPCODE:
    // 	console.log('reducer COMPANY_PROFILE_ZIPCODE', action)
    // 	return { ...state, loading: true }

    // case COMPANY_PROFILE_ZIPCODE_SUCCESS:
    // 	console.log('COMPANY_PROFILE_ZIPCODE_SUCCESS', action)
    // 	return { ...state, loading: false, selectZipCode: action.payload }

    // ==============Project=============

    case PROJECT_ADD:
      return { ...state, loading: true };

    case PROJECT_ADD_SUCCESS:
      return { ...state, loading: false, projectSetting: action.payload };

    case PROJECT_GET:
      return { ...state, loading: true };

    case PROJECT_GET_INVOICE:
      return { ...state, loading: true, loadingInvoice: true };

    case PROJECT_SET:
      return { ...state, loading: true };

    case PROJECT_SET_SUCCESS:
      return { ...state, loading: false, projectSettingSet: action.payload };

    case PROJECT_GET_SUCCESS:
      return {
        ...state,
        loading: false,
        loadingInvoice: false,
        projectSetting: action.payload,
        
      };

    case PROJECT_UPDATE:
      return { ...state, loading: true };

    case PROJECT_UPDATE_SUCCESS:
      return { ...state, loading: false, projectSetting: action.payload };

    case PROJECT_DELETE:
      return { ...state, loading: true };

    case PROJECT_DELETE_SUCCESS:
      return { ...state, loading: false, projectSetting: action.payload };

    // ==============User=============
    case USER_GET_EMAIL:
      return { ...state, loading: true };

    case USER_GET_EMAIL_SUCCESS:
      return { ...state, loading: false, userEmail: action.payload };

    case USER_GET:
      return { ...state, loading: true };

    case USER_GET_SUCCESS:
      return { ...state, loading: false, userSetting: action.payload };

    case ROLE_GET:
      return { ...state, loading: true };

    case ROLE_GET_SUCCESS:
      return { ...state, loading: false, roleSetting: action.payload };

    case ROLE_ADD:
      return { ...state, loading: true };

    case ROLE_ADD_SUCCESS:
      return { ...state, loading: false, roleSetting: action.payload };

    case ROLE_UPDATE:
      return { ...state, loading: true };

    case ROLE_UPDATE_SUCCESS:
      return { ...state, loading: false, roleSetting: action.payload };

    case ROLE_DELETE:
      return { ...state, loading: true };

    case USER_ADD:
      return { ...state, loading: true };

    case USER_UPDATE:
      return { ...state, loading: true };

    case USER_PASS_UPDATE:
      return { ...state, loading: true };

    case USER_DELETE:
      return { ...state, loading: true };

    case SEND_VERIFY:
      return { ...state, loading: true };

    case SEND_REST_PASSWORD:
      return { ...state, loading: false };

    case CONFIRM_VERIFY:
      return { ...state, loading: true };

    case CONFIRM_VERIFY_STATUS:
      return { ...state, loading: false, comfirmVerify: action.payload };

    case SEND_REQUEST:
      return { ...state, loading: false };

    // ==============Payment=============

    case PAYMENT_GET:
      return { ...state, loading: true };

    case PAYMENT_GET_SUCCESS:
      return { ...state, loading: false, paymentSetting: action.payload };

    case PAYMENT_ADD:
      return { ...state, loading: false };

    case PAYMENT_DELETE:
      return { ...state, loading: false };

    default:
      return { ...state };
  }

  
};
