import {
  CUSTOMER_INVOICE_GET,
  CUSTOMER_INVOICE_GET_SUCCESS,
  NAME_TITLE_GET,
  NAME_TITLE_GET_SUCCESS,
  OMISE_SEND_CUSTOMER,
  OMISE_SEND,
  OMISE_SEND_SUCCESS,
  WIDTHHOLDINGTAX_GET,
  WIDTHHOLDINGTAX_GET_SUCCESS,
  BILL_SLIP_SEND,
  BILL_SLIP_INVOICE_SEND,
  BANK_PAYMENT_GET,
  BANK_PAYMENT_GET_SUCCESS,
} from "../actions";

export const getBankPayment = (data) => {
  return {
    type: BANK_PAYMENT_GET,
    payload: data,
  };
};
export const getBankPaymentSuccess = (data) => {
  return {
    type: BANK_PAYMENT_GET_SUCCESS,
    payload: data,
  };
};
export const getCustomerInvoice = (data) => {
  return {
    type: CUSTOMER_INVOICE_GET,
    payload: data,
  };
};
export const getCustomerInvoiceSuccess = (data) => {
  return {
    type: CUSTOMER_INVOICE_GET_SUCCESS,
    payload: data,
  };
};
export const getWidthHoldingTax = (data) => {
  return {
    type: WIDTHHOLDINGTAX_GET,
    payload: data,
  };
};
export const getWidthHoldingTaxSuccess = (data) => {
  return {
    type: WIDTHHOLDINGTAX_GET_SUCCESS,
    payload: data,
  };
};
export const getNameTitle = (data) => {
  return {
    type: NAME_TITLE_GET,
    payload: data,
  };
};
export const getNameTitleSuccess = (data) => {
  return {
    type: NAME_TITLE_GET_SUCCESS,
    payload: data,
  };
};
export const sendOmise = (data) => {
  return {
    type: OMISE_SEND,
    payload: data,
  };
};
export const sendOmiseCustomer = (data) => {
  return {
    type: OMISE_SEND_CUSTOMER,
    payload: data,
  };
};
export const sendOmiseSuccess = (data) => {
  return {
    type: OMISE_SEND_SUCCESS,
    payload: data,
  };
};
export const sendBillSlip = (data) => {
  return {
    type: BILL_SLIP_SEND,
    payload: data,
  };
};
export const sendBillSlipInvoice = (data) => {
  return {
    type: BILL_SLIP_INVOICE_SEND,
    payload: data,
  };
};
