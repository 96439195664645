import React, { Component, Fragment } from 'react';
import { Modal } from 'reactstrap';
import { SpinnerEffect } from '../Animation/spinner-effect';
import ReactDOM from 'react-dom';

class SpinnerLoading extends Component {

    render() {
        return (
            // <Modal
            //     isOpen={this.props.modal}
            //     className="newloading"
            //     modalClassName="newloading"
            // >
            //     <SpinnerEffect />
            // </Modal>
            <div>
                {this.props.modal
                    ? <div className='newloading'>
                        <img
                            src='/media/loading/World 2.gif'
                            height='25%'
                        />
                    </div>
                    : null}
            </div>
        );
    }
}

export default SpinnerLoading;
