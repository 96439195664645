import {
    AUTH_USER
} from '../actions'
import {
    getAuthSuccess
} from './actions'

import {
    getCompanyprofile
} from '../settings/actions'

import apiSetting from '../../services/setting'
import { all, call, fork, put, takeEvery } from "redux-saga/effects";

function* getAuth({ payload }) {
 
    try {
        const dataRes = yield apiSetting.getAuth(payload);
      
        if (dataRes.length != 0) {
            localStorage.setItem('Auth_Token', dataRes[0].accessToken)
            yield put(getAuthSuccess(dataRes))


            console.log("auth_user[0]-Test Data ::",dataRes[0])
            // yield put(getCompanyp0rofile({ id_bill: dataRes[0].id_bill }))
        } else {
            // yield put(hotelInformationStatusError(`${dataRes.msg}`));
        }
    } catch (error) {
        console.log(error)
    }
}

export function* watchgetAuthUser() {
    yield takeEvery(AUTH_USER, getAuth);
}

export default function* rootSaga() {
    yield all([
        // =========AuthUser=======
        fork(watchgetAuthUser),
    ]);
}