import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import * as serviceWorker from './serviceWorker';
import { Provider } from 'react-redux';
import { configureStore } from './redux/store';
import App from './App'
import SpinnerLoading from "./components/Loading/SpinnerLoading";


// const App = React.lazy(() => import(/* webpackChunkName: "App" */'./App'));

// const root = ReactDOM.createRoot(document.getElementById('root'));
// root.render(

  ReactDOM.createRoot(document.getElementById("root")).render(
  <Provider store={configureStore()}>
    <Suspense fallback={<SpinnerLoading modal={true} />}>
      <App />
    </Suspense>
  </Provider>,

);
/*
 * If you want your app to work offline and load faster, you can change
 * unregister() to register() below. Note this comes with some pitfalls.
 * Learn more about service workers: https://bit.ly/CRA-PWA
 */
serviceWorker.unregister();
