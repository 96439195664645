import {
    AUTH_USER,
    AUTH_USER_SUCCESS
} from '../actions'

export const getAuth = (data) => ({
    type: AUTH_USER,
    payload: data
});

export const getAuthSuccess = (data) => ({
    type: AUTH_USER_SUCCESS,
    payload: data
});