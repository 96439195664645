module.exports = {
  "menu.app": "หน้าหลัก",
  "menu.admin": "เมนู",
  "menu.head_page_setting_a": "ตั้งค่า",
  "menu.head_page_a": "หน้า A",
  "menu.head_page_b": "หน้า B",

  //general
  "general.close": "ปิดหน้าต่าง",
  "general.cancel": "ยกเลิก",
  "general.save": "บันทึก",
  "general.edit": "แก้ไข",
  "general.view": "ดูรายละเอียด",

  ///1.Dashboard Page
  "dashboard.heading": "หน้าสรุป",
  "dashboard.payment": "",
  "dashboard.alert": "",
  "dashboard.number_of_project": "จำนวนโครงการ",
  "dashboard.payment_noti": "การแจ้งเตือนการชำระเงิน",
  "dashboard.logs": "บันทึกการทำงาน",
  "dashboard.receipt_notification": "การแจ้งเตือนการรับใบเสร็จ",
  //1.1 payment table
  "dashboard.no": "ลำดับ",
  "dashboard.due_date": "วันครบกำหนด",
  "dashboard.price": "ราคา (บาท)",
  "dashboard.status": "สถานะ",
  //1.2 receipt table
  "dashboard.no": "ลำดับ",
  "dashboard.receipt_no": "เลขที่ใบเสร็จ",
  "dashboard.price": "ราคา (บาท)",
  "dashboard.date": "วันที่",


  //2.Invoice Management Page
  "invoice.invoice_management": "ระบบจัดการใบแจ้งหนี้",
  "invoice.details": "รายละเอียดใบแจ้งหนี้",
  "invoice.payment_plan": "แผนการชำระเงิน",
  "invoice.contract_period": "ระยะเวลาสัญญา",
  "invoice.s_date": "วันเริ่มต้นสัญญา",
  "invoice.e_date": "วันสิ้นสุดสัญญา",
  //invoice tables
  "invoice_table_no": "ลำดับ",
  "invoice_table_invoice_no": "เลขที่ใบแจ้งหนี้",
  "invoice_table_start_date": "วันเริ่มต้นสัญญา",
  "invoice_table_end_date": "วันสิ้นสุดสัญญา",
  "invoice_table_due_date": "วันครบกำหนด",
  "invoice_table_price": "ราคา(บาท)",
  "invoice_table_status": "สถานะ",
  "invoice_table_receipt": "ใบเสร็จ",
  "invoice_table_action": "จัดการ",
  "invoice_table_paid": "ชำระแล้ว",
  "invoice_table_pending": "รอดำเนินการ",
  "invoice_table_checking": "กำลังตรวจสอบ",  

  //3.Contract Managemen Page
  "contract.contract_management": "ระบบจัดการสัญญา",
  "contract.details": "รายละเอียดสัญญา",

  //col1
  "contract.project_id": "รหัสโครงการ",
  "contract.address": "ที่อยู่",
  "contract.country": "ประเทศ",
  "contract.district": "เขต",
  "contract.province": "จังหวัด",
  "contract.Email": "อีเมล",
  "contract.payment_plan": "แผนการชำระเงิน",
  "contract.s_date": "วันเริ่มต้นสัญญา",
  "contract.privacy_agreement": "ข้อตกลงความเป็นส่วนตัว",
  "contract.refundable_policy": "นโยบายการคืนเงิน",
  "contract.product_description": "รายละเอียดผลิตภัณฑ์",
  //col2
  "contract.company_name": "ชื่อบริษัท",
  "contract.sub_district": "ตำบล",
  "contract.postal_code": "รหัสไปรษณีย์",
  "contract.phone_number": "หมายเลขโทรศัพท์",
  "contract.contract_period": "ระยะเวลาสัญญา",
  "contract.price": "ราคา",
  "contract.e_date": "วันสิ้นสุดสัญญา",
  "contract.Terms_&_Conditions": "ข้อกำหนดและเงื่อนไข",
  "contract.contract_document": "เอกสารสัญญา",
  "contract.customer_support": "บริการลูกค้า",
  //general in contract page
  "contract.view": "ดูรายละเอียด",
  "contract.description": "รายละเอียด",
  "contract.close": "ปิดหน้าต่าง",
  "contract.req_enable": "คำขอเพื่อเปิดใช้งาน",
  "contract.req_suspend": "คำขอเพื่อระงับการใช้งาน",

//4.Menu-admin
  "menu_admin.dashboard": "หน้าสรุป",
  "menu_admin.invoice": "ใบแจ้งหนี้",
  "menu_admin.contract": "สัญญา",
  "menu_admin.setting": "ตั้งค่า",
  //setting-sub-menut
  "menu_admin.company_profile": "โปรไฟล์บริษัท",
  "menu_admin.project_setting": "การตั้งค่าโครงการ",
  "menu_admin.payment_setting": "การตั้งค่าการชำระเงิน",
  "menu_admin.user_setting": "การตั้งค่าผู้ใช้",

  //5.Setting Page
    //5.1 company profile
    "setting.company_profile_heading": "ข้อมูลบริษัท",
      //5.1.1 Modal_edit - company
      "setting.company_profile": "ข้อมูลบริษัท",
      "setting.company_name": "ชื่อบริษัท",
      "setting.branch_no": "หมายเลขสาขา",
      "setting.head_office": "สำนักงานใหญ่",
      "setting.address": "ที่อยู่",
      "setting.address_2": "ที่อยู่ 2",
      "setting.country": "ประเทศ",
      "setting.province": "จังหวัด",
      "setting.district": "อำเภอ",
      "setting.sub_district": "ตำบล",
      "setting.postal_code": "รหัสไปรษณีย์",
      "setting.tax_id": "หมายเลขประจำตัวผู้เสียภาษี",
      "setting.currency_setup": "ตั้งค่าเงินตรา",

    //5.2 Project Setting
    "setting.project_setting": "การตั้งค่าโครงการ",
    "setting.project_setting_add": "เพิ่ม",
          //5.2.1 ps modal
          "setting.add_project": "เพิ่มโครงการ",
          "setting.select_project": "เลือกโครงการ",
    //5.3 Payment Setting
    "setting.payment_setting": "การตั้งค่าการชำระเงิน",
    //5.4 User Setting
    "setting.user": "การตั้งค่าผู้ใช้งาน",
    "setting.role": "บทบาท",
    "setting.role_details": "รายละเอียดบทบาทผู้ใช้",
    "setting.user_details": "ผู้ใช้งาน",
    "setting.add_user": "เพิ่มผู้ใช้งาน",
      //5.4.1 Role Modal
      "add_role.add_role": "เพิ่มบทบาท",
      "add_role.role_name": "ชื่อบทบาท",
      "add_role.feature": "ฟีเจอร์",
      "add_role.view": "ดู",
      "add_role.add_edit_pay": "เพิ่ม/แก้ไข/ชำระเงิน",
      "add_role.delete": "ลบ",
      "add_role.dashboard": "แดชบอร์ด",
      "add_role.invoice": "ใบแจ้งหนี้",
      "add_role.contract": "สัญญา",
      "add_role.setting": "การตั้งค่า",

      //5.4.2 Add User Modal
      "add_user.add_user": "เพิ่มผู้ใช้งาน",
      "add_user.first_name": "ชื่อ",
      "add_user.last_name": "นามสกุล",
      "add_user.nick_name": "ชื่อเล่น",
      "add_user.gender": "เพศ",
      "add_user.female": "หญิง",
      "add_user.male": "ชาย",
      "add_user.date_of_birth": "วันเกิด",
      "add_user.nationality": "สัญชาติ",
      "add_user.email": "อีเมล",
      "add_user.id_passport_no": "หมายเลขบัตรประชาชน/พาสปอร์ต",
      "add_user.address": "ที่อยู่",
      "add_user.address_2": "ที่อยู่ 2",
      "add_user.country": "ประเทศ",
      "add_user.province": "จังหวัด",
      "add_user.district": "อำเภอ",
      "add_user.sub_district": "ตำบล",
      "add_user.postal_code": "รหัสไปรษณีย์",
      "add_user.mobile_no": "หมายเลขโทรศัพท์มือถือ",
  //6.Topnav admin
    "topnav.personal_info": "ข้อมูลส่วนตัว",
    "topnav.change_pw": "เปลี่ยนรหัสผ่าน",
    "topnav.logout": "ออกจากระบบ",
    //personal info
    "topnav.basic_information": "ข้อมูลพื้นฐาน",
    "topnav.personal_firstName": "ชื่อ",
    "topnav.personal_lastName": "นามสกุล",
    "topnav.personal_nickName": "ชื่อเล่น",
    "topnav.personal_gender": "เพศ",
    "topnav.personal_male": "ชาย",
    "topnav.personal_female": "หญิง",
    "topnav.personal_dob": "วันเกิด",
    "topnav.personal_nationality": "สัญชาติ",
    "topnav.personal_email": "อีเมล",
    "topnav.personal_id_passport_no": "หมายเลขบัตรประชาชน/พาสปอร์ต",
    "topnav.personal_address": "ที่อยู่",
    "topnav.personal_address_2": "ที่อยู่ 2",
    "topnav.personal_country": "ประเทศ",
    "topnav.personal_province": "จังหวัด",
    "topnav.personal_district": "อำเภอ",
    "topnav.personal_sub_district": "ตำบล",
    "topnav.personal_postal_code": "รหัสไปรษณีย์",
    "topnav.personal_mobile_no": "หมายเลขโทรศัพท์มือถือ",
    //change password
    "topnav.change_password": "เปลี่ยนรหัสผ่าน",
    "topnav.current_password": "รหัสผ่านปัจจุบัน",
    "topnav.forgot_password": "ลืมรหัสผ่าน",
    "topnav.new_password": "รหัสผ่านใหม่",
    "topnav.verify_password": "ยืนยันรหัสผ่าน"

  








};
//<FormattedMessage id="contract.close" defaultMessage="CLOSE" />
